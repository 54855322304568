import { Auth0Client } from '@auth0/auth0-spa-js';
import EventEmitter from 'events';
import merge from 'lodash.merge';
import qs from 'qs';
import "regenerator-runtime";

const ID_TOKEN_EXPIRY_TIME = 36000; // This is the default expiry for Id Token.
const DEFAULT_OPTIONS = {
  redirectRoute: '',
  domain: 'cimpress.auth0.com',
  audience: 'https://api.cimpress.io/',
  useRefreshTokens: false,
  scope: 'openid profile email user_id',

  // number of seconds offset - value of 30 means token will be considered expired 30 seconds before it actually expires with the provider
  expirationOffset: 30,

  // Emit the "tokenExpired" event if the token is expired at the time of client subscription (e.g. auth.on('tokenExpired', ...))
  // "expirationOffset" is used when determining whether the initial "tokenExpired" event should be triggered or not.
  emitInitialTokenExpired: true,

  // Emit the "authenticated" event if the token is not expired at the time of client subscription (e.g. auth.on('authenticated', ...))
  emitInitialAuthenticated: true,

  // Check to see if the token is expired when the window comes into focus. This is because the expiration timer is sometimes unreliable.
  checkExpirationOnFocus: true,

  // number of seconds of clock skew to tolerate (see https://auth0.com/docs/libraries/lock/v10/customization#leeway-integer-)
  leeway: 30,

  // Check if ID token is required. If yes, then the token expiry would be that of Id Token (10 hours) rather than the expiry of access token.
  requireIDToken: false

};

export default class CentralizedAuth {
  // constructor options:
  //   clientID (required)
  //   redirectRoute (optional, see default above)
  //   domain (optional, see default above)
  //   audience (optional, see default above)
  //   scope (optional, see default above)
  //   expirationOffset (optional, see default above)
  //   emitInitialTokenExpired (optional, see default above)
  //   emitInitialAuthenticated (optional, see default above)
  //   checkExpirationOnFocus (optional, see default above)
  //   leeway (optional, see default above)
  //   lockWidgetOptions (optional, see defaults in newLockWidget)
  constructor (options) {
    merge(this, DEFAULT_OPTIONS, options);
    this.redirectUri = window.location.origin + this.redirectRoute;
    // PKCE spa auth0 js implementation
    this.auth0 = new Auth0Client({
      cacheLocation: 'localstorage',
      domain: this.domain,
      client_id: this.clientID,
      responseType: 'id_token token',
      audience: this.audience,
      scope: this.scope,
      useRefreshTokens: this.useRefreshTokens,
      redirect_uri: this.redirectUri, //auth0 redirects back to this page after authentication
      leeway: this.leeway // avoid the "The token was issued in the future. Please check your computed clock." error
    });

    this.events = new EventEmitter();

    // listen for changes to localStorage that don't originate from this current window/tab
    window.addEventListener('storage', this.listenToStorage);

    // The tokenExpired event can be unreliable (for example when your computer goes to sleep)
    // So we are double checking that you are logged in when the browser tab gains focus.
    window.addEventListener('visibilitychange', this.handleFocusChange);
  }

  wasAuth0Redirect = () => {
    const parsedUrl = this.getFragments();
    return parsedUrl['code'] && parsedUrl['state'];
  };

  // Extracts code and state params from the url and returns a dictionary.
  getFragments = () => {
    if (!window.location.search) {
      return {};
    }

    return window.location.search
      .substring(1)
      .split('&')
      .reduce(function (prev, cur) {
        var kv = cur.split('=');
        prev[kv[0]] = kv[1];
        return prev;
      }, {});
  };

  // Subscribe to events that the auth wrapper emits
  // Subscribable event types:
  // 1. tokenExpired: (expirationTime) =>
  //    * Fired when the amount of time the token is good for (minus the "expirationOffset") has elapsed
  //    * Fired if there exists an expired token in localStorage at the time of subscription
  //      - (configurable via "emitInitialTokenExpired" option)
  // 2. logout:
  //    * Fired when user is logged out
  // 3. authenticated: (source) =>
  //    * Fired if there exists an unexpired token in localStorage at the time of subscription
  //      - (configurable via "emitInitialAuthenticated" option)
  //      - source will be === "initial" in the provided callback for this scenario
  //      - it is also implied that the current window/tab is the source of the event
  //    * Fired when "this" instance of the authWrapper saves the user's login information to localStorage
  //      - source will be === "this" to denote that the event is coming from this instance
  //    * If the current window/tab does not have focus (i.e. blurred), this is fired when a new expiration is set
  //      in localStorage from a different window/tab with the same origin (e.g. 2 copies of the same app and you
  //      sign-in to one)
  //      - source will be === "storage" in the provided callback to denote that the authentication
  //        happened in a different window/tab
  on = (eventType, ...args) => {
    this.events.on(eventType, ...args);

    if (eventType === 'tokenExpired' && this.emitInitialTokenExpired) {
      const expiration = this.getExpiration();
      if (expiration && expiration - (this.expirationOffset * 1000) <= new Date().getTime()) {
        this.events.emit('tokenExpired', expiration);
      }
    }

    if (eventType === 'authenticated' && this.emitInitialAuthenticated) {
      if (this.isLoggedIn()) {
        this.events.emit('authenticated', 'initial');
      }
    }
  };

  removeListener = (eventType, ...args) => {
    this.events.removeListener(eventType, ...args);
  };

  handleFocusChange = () => {
    if (document.visibilityState === 'visible' && this.checkExpirationOnFocus) {
      const expiration = this.getExpiration();
      if (expiration && expiration - (this.expirationOffset * 1000) <= new Date().getTime()) {
        this.events.emit('tokenExpired', expiration);
      }
    }
  }

  // Assumes the token details have already been stored to local storage.
  setExpirationTimer = () => {
    // Get the time that the access token will expire at
    const expiresAt = localStorage.getItem('expires_at');

    if (!expiresAt) {
      return;
    }

    try {
      this.clearTimeout();
      const timeToWait = expiresAt - new Date().getTime() - (1000 * this.expirationOffset);
      this.expiresTimeout = window.setTimeout(
        () => this.events.emit('tokenExpired', Number.parseInt(expiresAt, 10)),
        timeToWait,
      );
    } catch (err) {
      console.warn('Failed to set token expiration monitor');
    }
  };

  // It is possible to create an infinite loop between 2 tabs/windows if localStorage is modified
  // within this listener - so don't do it :)
  listenToStorage = (e) => {
    switch (e.key) {
      // TODO: add storage namespace option (e.g. "expires_at" -> "saw:expires_at") apps will likely break this wrapper's
      // behavior if they use any of the localStorage keys this wrapper relies on
      case 'expires_at':
        // check to see if it's being removed or not
        if (e.newValue) {
          try {
            const expiresAt = JSON.parse(e.newValue);
            this.setExpirationTimer(expiresAt);
            this.events.emit('authenticated', 'storage');
          } catch (e) {
            // for JSON.parse: shouldn't happen unless people are modifying storage manually
          }
        }
        break;
      default:
    }
  };

  // Check whether the current time is past the access token's expiry time.
  isLoggedIn = () => {
    try {
      const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      return new Date().getTime() < (expiresAt - (this.expirationOffset * 1000));
    } catch (e) {
      return false;
    }
  };

  saveToken = (token, accessToken, profile, expiresIn, refreshToken) => {
    localStorage.setItem('token', token);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('profile', JSON.stringify(profile));
    this.useRefreshTokens && refreshToken && localStorage.setItem('refreshToken', refreshToken);

    if (expiresIn) {
      // Set the time that the access token will expire at
      const expiresAt = expiresIn * 1000 + new Date().getTime();
      // This will trigger listenToStorage on "blurred" tabs/windows of the same origin
      // It will not trigger this.listenToStorage in the current tab/window because that's not how StorageEvent's work
      localStorage.setItem('expires_at', JSON.stringify(expiresAt));

      this.events.emit('authenticated', 'this');
    }
  };

  clearTimeout = () => this.expiresTimeout = window.clearTimeout(this.expiresTimeout);

  removeToken = () => {
    this.clearTimeout();
    localStorage.removeItem('expires_at');
    localStorage.removeItem('token');
    localStorage.removeItem('delegationTokens');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('profile');
  };

  getToken = () => localStorage.getItem('token');

  getAccessToken = () => localStorage.getItem('accessToken');

  getRefreshToken = () => localStorage.getItem('refreshToken');

  getProfile = () => {
    try {
      return JSON.parse(localStorage.getItem('profile')) || undefined;
    } catch (e) { }
  };

  getExpiration = () => {
    try {
      return JSON.parse(localStorage.getItem('expires_at')) || undefined;
    } catch (e) { }
  };

  updateProfile = (profile) => localStorage.setItem("profile", JSON.stringify(profile));

  // unfortunate, but localStorage can fill up if this isn't called.
  // should only be called after successful authentication has completed to avoid
  // removing in process nonces
  // https://github.com/auth0/auth0.js/issues/402
  clearOldNonces = () => Object.keys(localStorage).forEach(key => {
    if (!key.startsWith('com.auth0.auth')) return;
    localStorage.removeItem(key);
  });

  // returns a proimse that resolves with an authenticated status (true, false)
  // will not redirect
  handleAuthentication = async () => {
    if (this.isLoggedIn()) {
      this.setExpirationTimer();
      return Promise.resolve(true);
    }
    if (this.wasAuth0Redirect()) {
      let authResult = {};
      try {
        await this.auth0.handleRedirectCallback();
        const cacheKeys = await this.auth0.cacheManager.cache.allKeys();

        if (cacheKeys.length > 1) {
          // Multiple cache entries detected, clear all to avoid issues
          // @see https://gitlab.com/Cimpress-Technology/internal-open-source/component-library/mex-simple-auth-wrapper/-/merge_requests/63
          this.auth0.cacheManager.clearSync();
        } else if (cacheKeys.length === 1) {
          const cacheResult = await this.auth0.cacheManager.cache.get(cacheKeys[0]);

          if (cacheResult && cacheResult.body) {
            authResult = cacheResult.body;
          }
        }
      } catch (error) {
        // apps should handle this themselves
        console.log(error);
        throw error;
      }

      if (authResult && authResult.access_token && authResult.id_token) {
        window.location.hash = '';
        this.clearOldNonces();
        this.saveToken(authResult.id_token,authResult.access_token,authResult.decodedToken.user,this.requireIDToken ? ID_TOKEN_EXPIRY_TIME : authResult.expires_in,authResult.refresh_token);
        const returnUri = sessionStorage.getItem('returnUri');
        if (returnUri) {
          sessionStorage.removeItem('returnUri');
        }
        window.location = returnUri || '/';
        this.setExpirationTimer();
        return true;
      }
      return false;
    }
    return false;
  };

  // Checks whether the app is authenticated.  If not it automatically initiates authentication.
  // Refer to README for documentation on method arguments
  // returns a promise that resolves with the authentication status (true, false)
  // can result in redirect
  login = async (options = {}) => {
    // this isn't pretty, but it makes this method backwards compatible
    // TODO: v7.0 remove backwards compatibilty
    if (typeof options === 'string') {
      options = {
        nextUri: options,
        forceLogin: false
      };
      console.warn('Calling login("string") is deprecated. Please refer to the documentation and pass an options object instead.');
    }

    const { forceLogin, nextUri, authorizeParams = {} } = options;

    if (this.isLoggedIn() && !forceLogin) {
      return Promise.resolve(true);
    }
    // fix for authorizeParams having depth more than zero
    let authorizeParamsZeroDepth = qs.parse(qs.stringify(authorizeParams), { depth: 0 });
    let authOptions = { redirect_uri: this.redirectUri, ...authorizeParamsZeroDepth };

    if(forceLogin) {
      if (nextUri) {
        sessionStorage.setItem('returnUri', nextUri);
      }
      await this.auth0.loginWithRedirect(authOptions);
      return false;
    }
    // try silent sso first
    try {
      await this.auth0.getTokenSilently(authOptions);
      const authResult = this.auth0.cacheManager.cache.get(this.auth0.cacheManager.cache.allKeys()).body;
      if (authResult && authResult.access_token && authResult.id_token) {
        this.clearOldNonces();
        this.saveToken(authResult.id_token, authResult.access_token, authResult.decodedToken.user, this.requireIDToken ? ID_TOKEN_EXPIRY_TIME : authResult.expires_in, authResult.refresh_token);
        this.setExpirationTimer();
        return true;
      }
      return false;
    } catch (err) {
      console.log(err);
      if (nextUri) {
        sessionStorage.setItem('returnUri', nextUri);
      }
      await this.auth0.loginWithRedirect(authOptions);
      return false;
    }
  };

  /// combines handleAuthentication and login to make sure the user is logged in
  /// and parse the hash on return in on function
  /// Refer to README for documentation on method arguments
  ensureAuthentication = (options = {}) => {
    // this isn't pretty, but it makes this method backwards compatible
    // TODO: v7.0 remove backwards compatibilty
    if (typeof options === 'string') {
      console.warn('Calling ensureAuthentication("string") is deprecated. Please refer to the documentation and pass an options object instead.');
      options = {
        nextUri: options,
        forceLogin: false
      };
    }

    const { forceLogin } = options;
    if (forceLogin) {
      return this.login(options);
    }

    return this.handleAuthentication().then((authenticated) => {
      if (authenticated) {
        return Promise.resolve(true);
      }

      return this.login(options);
    });
  };

  // can result in redirect
  logout = (nextUri, logoutOfFederated) => {
    // logout locally
    this.removeToken();
    this.events.emit('logout');
    // also log out with auth0
    const returnTo = nextUri ? { returnTo: window.location.origin + nextUri } : {};
    if (logoutOfFederated) {
      this.auth0.logout({
        client_id: this.clientID,
        federated: true,
        ...returnTo
      });
    } else {
      this.auth0.logout({
        client_id: this.clientID,
        ...returnTo
      });
    }
  };

}
